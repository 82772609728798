var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.ValidationObserver,
    { ref: "searchBag" },
    [
      _c(
        "v-form",
        [
          _c(
            "v-container",
            { staticClass: "boxed", attrs: { fluid: "" } },
            [
              _c("header", [_vm._v("BAG")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "BagId", rules: "numeric" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "ID",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _setup.bag.id,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.bag, "id", $$v)
                                    },
                                    expression: "bag.id",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", "align-self": "center" } },
                    [_vm._v(" of ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c(_setup.ValidationProvider, {
                        attrs: { name: "Postcode", rules: "postalcode" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    label: "Postcode",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _setup.bag.postalCode,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.bag, "postalCode", $$v)
                                    },
                                    expression: "bag.postalCode",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          label: "Huisnummer",
                          outlined: "",
                          "persistent-placeholder": "",
                        },
                        model: {
                          value: _setup.bag.houseNumber,
                          callback: function ($$v) {
                            _vm.$set(_setup.bag, "houseNumber", $$v)
                          },
                          expression: "bag.houseNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          label: "Toevoeging",
                          outlined: "",
                          "persistent-placeholder": "",
                        },
                        model: {
                          value: _setup.bag.houseNumberAddition,
                          callback: function ($$v) {
                            _vm.$set(_setup.bag, "houseNumberAddition", $$v)
                          },
                          expression: "bag.houseNumberAddition",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "form-group", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          on: {
                            click: function ($event) {
                              return _setup.onSearchBag()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-magnify"),
                          ]),
                          _vm._v(" Zoek "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        staticClass: "bag",
                        attrs: {
                          dense: "",
                          "show-select": "",
                          "disable-sort": "",
                          "disable-pagination": "",
                          headers: _setup.headers,
                          "hide-default-footer": "",
                          items: _setup.bagOverview,
                          loading: _setup.bagLoading,
                          "item-class": _setup.itemRowBackground,
                          "item-key": "key",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `header.data-table-select`,
                              fn: function () {
                                return undefined
                              },
                              proxy: true,
                            },
                            {
                              key: `item.data-table-select`,
                              fn: function ({ isSelected, select, item }) {
                                return [
                                  !item.residenceId || item.primaryAddress
                                    ? [
                                        _c("v-simple-checkbox", {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple",
                                            },
                                          ],
                                          attrs: {
                                            color: "primary",
                                            value: isSelected,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return select($event)
                                            },
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: `item.constructionDate`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _setup.formatDateCustom(
                                          item.constructionDate,
                                          "YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.buildingId`,
                              fn: function ({ item }) {
                                return [
                                  !item.residenceId
                                    ? [_vm._v(" " + _vm._s(item.buildingId))]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: `item.residenceId`,
                              fn: function ({ item }) {
                                return [
                                  item.primaryAddress
                                    ? [_vm._v(" " + _vm._s(item.residenceId))]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: `item.go`,
                              fn: function ({ item }) {
                                return [
                                  !item.residenceId || item.primaryAddress
                                    ? [_vm._v(" " + _vm._s(item.go))]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: `item.typeDescription`,
                              fn: function ({ item }) {
                                return [
                                  !item.residenceId || item.primaryAddress
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(item.typeDescription)
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: `item.addressTypeDescription`,
                              fn: function ({ item }) {
                                return [
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.addressTypeDescription)
                                    ),
                                  ],
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _setup.bagSelected,
                          callback: function ($$v) {
                            _setup.bagSelected = $$v
                          },
                          expression: "bagSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }